import { n as normalizeComponent, F as FasHeader, A as AssetCard, M as MyMixin } from "./index.js";
import { ADD_PERMISSION, EXPORT_PERMISSION, FAS_ASSET } from "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("fas-header", {
    attrs: {
      "redirect-route": "/fixed-asset-register"
    },
    scopedSlots: _vm._u([{
      key: "customTitle",
      fn: function() {
        return [_c("h2", {
          staticClass: "m-0 mr-2"
        }, [_vm._v(" " + _vm._s(_vm.$route.query.fullName) + " ")]), _c("a-tag", {
          attrs: {
            "color": _vm.$route.query.status === "true" ? `cyan` : ``
          }
        }, [_vm._v(" " + _vm._s(_vm.$route.query.status === "true" ? "Open" : "Closed") + " ")])];
      },
      proxy: true
    }, {
      key: "customButton",
      fn: function() {
        return [_c("a-button", {
          staticClass: "mr-2",
          attrs: {
            "disabled": !_vm.can(_vm.permissions.export)
          },
          on: {
            "click": _vm.onExportClick
          }
        }, [_c("a-icon", {
          attrs: {
            "type": "export"
          }
        }), _vm._v(" Export Report ")], 1), _c("a-button", {
          attrs: {
            "type": "primary",
            "disabled": _vm.$route.query.status === "false" || !_vm.can(_vm.permissions.add)
          },
          on: {
            "click": function($event) {
              return _vm.pushToRouteWithQuery(`/assets/create`);
            }
          }
        }, [_vm._v(" Add New Asset ")])];
      },
      proxy: true
    }])
  }), _c("div", {
    staticClass: "d-flex justify-content-between align-items-center px-5 pt-4"
  }, [_c("span", [_vm._v(" Number of assets count: " + _vm._s(_vm.total) + " ")]), _c("div", {
    staticClass: "asset-list__filter d-flex"
  }, [_c("keyword-filter", {
    staticClass: "mr-2",
    attrs: {
      "field": "Barcode,Location",
      "placeholder": "Search by Barcode, Location"
    }
  }), _c("a-radio-group", {
    attrs: {
      "default-value": _vm.listType
    },
    on: {
      "change": _vm.onSwitchList
    }
  }, [_c("a-radio-button", {
    attrs: {
      "value": "listThumb"
    }
  }, [_c("a-icon", {
    attrs: {
      "type": "appstore"
    }
  })], 1), _c("a-radio-button", {
    attrs: {
      "value": "list"
    }
  }, [_c("a-icon", {
    attrs: {
      "type": "unordered-list"
    }
  })], 1)], 1)], 1)]), _vm.listType === `list` && !_vm.isOnIpad ? _c("list-composer", {
    attrs: {
      "has-active": false,
      "custom-padding-table": "pt-0 px-5 pb-5"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(_ref) {
        var record = _ref.record;
        return [_c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Detail")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "edit",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.onEdit(record);
            }
          }
        })], 1)];
      }
    }], null, false, 2232532786)
  }, [_c("url-field", {
    key: "Barcode",
    attrs: {
      "data-index": "barcode",
      "title": "Barcode",
      "base-url": "/assets",
      "query": _vm.urlFieldQuery
    }
  }), _c("text-field", {
    key: "CreatedAt",
    attrs: {
      "data-index": "createdAt",
      "title": "Captured Date & Time",
      "parse": _vm.parseDate
    }
  }), _c("text-field", {
    key: "FullName",
    attrs: {
      "data-index": "fullName",
      "title": "Captured User"
    }
  }), _c("text-field", {
    key: "Location",
    attrs: {
      "data-index": "location",
      "title": "Location"
    }
  }), _c("text-field", {
    key: "Comment",
    attrs: {
      "data-index": "comment",
      "title": "Comments"
    }
  }), _c("text-field", {
    key: "Attachments",
    attrs: {
      "data-index": "attachments",
      "title": "Attachments",
      "parse": _vm.parseAttachments
    }
  })], 1) : _vm._e(), _vm.listType === `list` && _vm.isOnIpad ? _c("list-composer", {
    attrs: {
      "has-active": false,
      "has-action": false,
      "custom-padding-table": "pt-0 px-5 pb-5"
    }
  }, [_c("url-field", {
    key: "Barcode",
    attrs: {
      "data-index": "barcode",
      "title": "Barcode",
      "base-url": "/assets",
      "query": _vm.urlFieldQuery
    }
  }), _c("text-field", {
    key: "CreatedAt",
    attrs: {
      "data-index": "createdAt",
      "title": "Captured Date & Time",
      "parse": _vm.parseDate
    }
  }), _c("text-field", {
    key: "FullName",
    attrs: {
      "data-index": "fullName",
      "title": "Captured User"
    }
  }), _c("text-field", {
    key: "Location",
    attrs: {
      "data-index": "location",
      "title": "Location"
    }
  })], 1) : _vm._e(), _vm.listType === `listThumb` ? _c("div", {
    staticClass: "asset-list-thumb px-5 pt-3"
  }, [_c("a-list", {
    attrs: {
      "grid": {
        gutter: 18,
        md: 2,
        lg: 4
      },
      "data-source": _vm.listAsset
    },
    scopedSlots: _vm._u([{
      key: "renderItem",
      fn: function(item) {
        return _c("a-list-item", {}, [_c("asset-card", {
          attrs: {
            "record": item
          }
        })], 1);
      }
    }], null, false, 3096339299)
  }), _c("a-pagination", {
    staticClass: "d-flex justify-content-end mb-5",
    attrs: {
      "show-size-changer": "",
      "current": _vm.currentPage,
      "total": _vm.total,
      "page-size": _vm.pageSize
    },
    on: {
      "showSizeChange": _vm.onPageSizeChange,
      "change": _vm.onPageChange
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "AssetList",
  inject: ["crud", "can", "apiUrl"],
  components: { FasHeader, AssetCard },
  mixins: [MyMixin],
  data() {
    const { id, status, fullName } = this.$route.query;
    return {
      FasHeader,
      AssetCard,
      listType: "listThumb",
      isOnIpad: false,
      permissions: {
        add: ADD_PERMISSION,
        export: EXPORT_PERMISSION
      },
      urlFieldQuery: {
        id,
        status,
        fullName
      }
    };
  },
  computed: {
    total() {
      return this.crud.getPagination().count;
    },
    currentPage() {
      return this.crud.getPagination().page;
    },
    pageSize() {
      return this.crud.getPagination().pageSize;
    },
    listAsset() {
      return this.crud.getList();
    }
  },
  async created() {
    if (screen.width <= 768) {
      this.isOnIpad = true;
    }
    this.crud.deleteFilter("IsInactive");
    await this.crud.setFilter("AssetRegisterId", {
      operator: "Eq",
      value: this.$route.query.id
    });
    await this.crud.fetchList();
  },
  methods: {
    onSwitchList({ target: { value } }) {
      this.listType = value;
    },
    onEdit(record) {
      this.pushToRouteWithQuery(`/assets/${record.id}`);
    },
    onExportClick() {
      this.axios.get(this.apiUrl + `/fas/assets/export-list?assetRegisterId=${this.$route.query.id}`, {
        responseType: "blob"
      }).then((res) => {
        const { data, headers } = res;
        this.stringToCsvFile(headers["filename"], data, true, headers["content-type"]);
      }).catch((err) => this.showErrorMsg(err));
    },
    onPageChange(value) {
      this.crud.setPagination({ page: value });
      this.crud.fetchList();
    },
    onPageSizeChange(current, pageSize) {
      this.crud.setPagination({ page: current, pageSize });
      this.crud.fetchList();
    },
    parseDate(value) {
      return this.formatDate(value);
    },
    parseAttachments(attachments) {
      if (attachments.length == 0)
        return "";
      if (attachments.length == 1) {
        return attachments[0].name;
      }
      return attachments[0].name + ", " + attachments[1].name;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var AssetList = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "asset-list"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "fas.assets",
      "api-url": _vm.apiUrl,
      "page": _vm.page
    }
  }, [_c("asset-list")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    AssetList
  },
  data() {
    return {
      AssetList,
      apiUrl,
      page: FAS_ASSET,
      itemsMenu: [
        {
          key: "fixedAssetRegister",
          title: "Fixed Asset Register",
          path: "/fixed-asset-register"
        },
        {
          key: "fixedAssetRegister",
          title: this.$route.query.fullName,
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
